<template>
  <v-container fluid class="pt-0 non-printable grey lighten-4">
    <v-dialog v-model="printPageModal" fullscreen class="media-print">
      <PrintPage @closeModal="printPageModal = false" :email_send_button="true" :address="flat.full_address"/>
    </v-dialog>
    <v-dialog v-model="reportPageModal" fullscreen class="media-print">
      <PrintReport @closeModal="reportPageModal = false"
                   :flat="flat"
                   :send_by_email_button="true"
                   :component_name="print_report_component_name"
                   :payload_add_int_1="print_report_additional_id"
      />
    </v-dialog>
    <v-dialog v-if="show_event_logger" v-model="show_event_logger" max-width="96%">
      <v-card height="84vh">
        <UserEventLogger :flat_id="flat.id"/>
      </v-card>
    </v-dialog>
    <v-dialog v-if="show_flat_notes" v-model="show_flat_notes" max-width="90%">
      <v-card height="84vh">
        <FlatNotes :person_hash="modal_person_hash || flat.person_hash" @afterFetch="afterComponentNotesUpdate"/>
      </v-card>
    </v-dialog>
    <v-dialog v-if="show_court_cases" v-model="show_court_cases" max-width="90%">
      <v-card height="84vh">
        <FlatCourtCases :person_hash="modal_person_hash || flat.person_hash" @afterFetch="afterComponentCourtCasesUpdate"/>
      </v-card>
    </v-dialog>
    <v-dialog max-width="1100" v-model="show_search_window" v-if="show_search_window">
      <v-card height="76vh">
        <v-card-title class="pt-2 pb-2">
          Пошук абонента/помешканя
        </v-card-title>
        <v-card-text>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Пошук"
              color="grey darken-1"
              placeholder="Почніть вводити текст для пошуку"
              single-line
              hide-details
              class="mb-1"
              @input="searchFlatByText"
              filled
          />
        </v-card-text>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="search_flats"
              :items-per-page="15"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [5, 15, 30, -1],

                        }"
              @click:row="openWorkPlace"
              class="custom-table"
          >
            <template v-slot:item.person_id="{ item }">
              <v-icon class="mr-2" color="error" size="22" v-if="item.disable">mdi-alert</v-icon>
              <v-icon class="mr-2" color="secondary" size="22" v-if="item.not_living">mdi-store</v-icon>
              <v-icon class="mr-2" color="grey darken-1" size="22" v-else>mdi-domain</v-icon>
              <span>{{ item.person_id }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row justify="center" style="min-height: 100vh; flex-direction: column">
      <v-col cols="12" md="12" class="pb-1" style="flex: 0">
        <v-row class="mt-1" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="pt-0 pb-1">
            <v-card v-if="!flat.disable">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="success" size="32">
                    mdi-home-outline
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Особовий рахунок: {{ flat.person_id }}</v-list-item-title>
                  <v-list-item-subtitle>{{ flat.full_address }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card v-else>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="error darken-1" size="32">
                    mdi-alert-circle
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Особовий (закритий): {{ flat.person_id }}</v-list-item-title>
                  <v-list-item-subtitle>{{ flat.full_address }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col class="pt-0 pb-1">
            <v-card>
              <v-list-item v-if="flat">
                <v-list-item-icon>
                  <v-icon color="secondary" size="32">
                    mdi-account-check
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Власник:</v-list-item-title>
                  <v-list-item-subtitle>{{
                      flat.flat_owner === undefined || flat.flat_owner === null ? 'Власник відсутній' : flat.flat_owner.full_name
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col class="pt-0 pb-1">
            <v-card>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="error" size="32">
                    mdi-calendar-check
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Поточний місяць:</v-list-item-title>
                  <v-list-item-subtitle class="text-capitalize">{{
                      current_month | formatDate('MMMM YYYY')
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-1" v-if="$vuetify.breakpoint.smAndDown">
          <v-col cols="12">
            <v-sheet
                class="mx-auto transparent"
                max-width="100%"
            >
              <v-slide-group
                  v-model="slider"
                  center-active
                  class="slider"
              >
                <v-slide-item class="px-1">
                  <v-card
                      class="mx-4 mb-1"
                      width="310"
                      v-if="!flat.disable"
                  >
                    <v-list-item>
                      <v-list-item-icon class="mr-3">
                        <v-icon color="success" size="32">
                          mdi-home-outline
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>№ особ.рах: {{ flat.person_id }}</v-list-item-title>
                        <v-list-item-subtitle style="white-space: normal">{{ flat.full_address }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>

                  <v-card
                      class="mx-4 mb-1"
                      width="310"
                      v-else
                  >
                    <v-list-item>
                      <v-list-item-icon class="mr-3">
                        <v-icon color="error darken-1" size="32">
                          mdi-alert-circle
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>О.Р (закритий): {{ flat.person_id }}</v-list-item-title>
                        <v-list-item-subtitle style="white-space: normal">{{ flat.full_address }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card
                      class="mx-4 mb-1"
                      width="300"
                  >
                    <v-list-item v-if="flat">
                      <v-list-item-icon class="mr-3">
                        <v-icon color="secondary" size="32">
                          mdi-account-check
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Власник:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            flat.flat_owner === undefined || flat.flat_owner === null ? 'Власник відсутній' : flat.flat_owner.full_name
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card
                      class="mx-4 mb-1"
                      width="200"
                  >
                    <v-list-item>
                      <v-list-item-icon class="mr-3">
                        <v-icon color="error" size="32">
                          mdi-calendar-check
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Місяць:</v-list-item-title>
                        <v-list-item-subtitle class="text-capitalize">{{
                            current_month | formatDate('MMMM YYYY')
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 py-0">
            <v-toolbar elevation="0" class="py-0 grey lighten-4">
              <v-tooltip bottom v-if="!modal_item">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain class="grey lighten-2"
                         :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                         v-bind="attrs" v-on="on" :disabled="!prev_button_visible"
                         @click.stop="nextItem($route.params.person_hash, false)"
                  >
                    <v-icon :left="$vuetify.breakpoint.smAndUp" :size="$vuetify.breakpoint.smAndUp ? 22 : 30">
                      mdi-chevron-left
                    </v-icon>
                    {{ $vuetify.breakpoint.smAndUp ? 'Попередній' : '' }}
                  </v-btn>
                </template>
                <span>Попередній абонент</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!modal_item">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain
                         :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                         class="grey lighten-2" v-bind="attrs" v-on="on" :disabled="!next_button_visible"
                         @click.stop="nextItem($route.params.person_hash, true)"
                  >
                    {{ $vuetify.breakpoint.smAndUp ? 'Наступний' : '' }}
                    <v-icon :right="$vuetify.breakpoint.smAndUp" :size="$vuetify.breakpoint.smAndUp ? 22 : 30">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </template>
                <span>Наступний абонент</span>
              </v-tooltip>
              <v-tooltip bottom v-if="modal_item">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain
                         :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-0 mr-2'"
                         class="grey lighten-2" v-bind="attrs" v-on="on" :disabled="!next_button_visible"
                         @click.stop="closeModalItem"
                  >
                    Закрити
                    <v-icon :right="$vuetify.breakpoint.smAndUp" :size="$vuetify.breakpoint.smAndUp ? 22 : 30">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span>Закрити вікно</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain class="grey lighten-3"
                         :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                         v-bind="attrs" v-on="on"
                         @click="openUserEventLogger">
                    <v-icon
                        :size="$vuetify.breakpoint.smAndUp ? 22 : 30"
                        color="secondary darken-1"
                    >mdi-history
                    </v-icon>
                  </v-btn>
                </template>
                <span>Журнал дій</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain class="grey lighten-3"
                         :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                         v-bind="attrs" v-on="on"
                         @click="openSearchWindow">
                    <v-icon
                        :size="$vuetify.breakpoint.smAndUp ? 22 : 30"
                        color="teal darken-1"
                    >mdi-home-search-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Пошук абонента</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                      :color="flat_notes.warning ? 'error' : flat_notes.count ? 'success' : 'transparent'"
                      overlap
                      v-bind="attrs" v-on="on"
                      :content="flat_notes.count"
                  >
                    <v-btn medium plain class="grey lighten-3"
                           :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                           v-bind="attrs" v-on="on"
                           @click="openFlatNotes">
                      <v-icon
                          :size="$vuetify.breakpoint.smAndUp ? 22 : 30"
                          color="secondary darken-1"
                      >mdi-card-account-phone-outline
                      </v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>Коментарі / Дзвінки</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                      :color="court_cases.count ? 'success' : 'transparent'"
                      overlap
                      v-bind="attrs" v-on="on"
                      :content="court_cases.count"
                  >
                    <v-btn medium plain class="grey lighten-3"
                           :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                           v-bind="attrs" v-on="on"
                           @click="openCourtCaseNotes">
                      <v-icon
                          :size="$vuetify.breakpoint.smAndUp ? 22 : 30"
                          color="error lighten-1"
                      >mdi-gavel
                      </v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>Претензії / Судові справи</span>
              </v-tooltip>

              <v-spacer></v-spacer>
              <v-menu offset-y offset-overflow nudge-bottom="3" nudge-width="120" close-on-content-click>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon class="ml-2 mr-2 grey lighten-2" v-on="{ ...tooltip, ...menu }" v-bind="attrs"
                             height="42" width="42">
                        <v-icon color="grey darken-2" size="22">mdi-bookshelf</v-icon>
                      </v-btn>
                    </template>
                    <span>Архів/Рахунки</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item
                      @click.stop="openReportPage('archive_report_by_flat')"
                      dense>
                    <v-list-item-icon>
                      <v-icon color="secondary">mdi-file-document-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Архів</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      @click.stop="openReportPage('receipt_simply')"
                      dense>
                    <v-list-item-icon>
                      <v-icon color="success">mdi-text-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Спрощений рахунок</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      @click.stop="openReportPage('receipt_detail')"
                      dense>
                    <v-list-item-icon>
                      <v-icon color="success">mdi-text-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Детальний рахунок</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      @click.stop="openReportPage('receipt_detail_with')"
                      dense>
                    <v-list-item-icon>
                      <v-icon color="success">mdi-text-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Детальний рахунок (з корінцем)</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      @click.stop="openReportPage('warning_standart')"
                      dense>
                    <v-list-item-icon>
                      <v-icon color="success">mdi-text-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Попередження про заборгованість</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-menu
                      transition="slide-y-transition"
                      left
                      offset-x offset-overflow
                      v-if="additional_print_form.length"
                  >
                    <template v-slot:activator="{ on, attrs, value }">
                      <v-list-item dense v-on="on" v-bind="attrs" class="d-flex align-center">
                        <v-list-item-icon class="my-0" style="align-self: center">
                          <v-icon color="error">mdi-printer-pos-star-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Додаткові друковані форми</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon>
                            {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <v-list-item
                        v-for="item in additional_print_form"
                        :key="item.value" class="white"
                        @click.stop="openReportPage('universal_spreed_sheet_report_by_flat', item.value)"
                        dense>
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-printer-pos-star-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-menu>
                </v-list>
              </v-menu>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-2 mr-2 grey lighten-2" v-on="on" v-bind="attrs" @click.stop="openPrintPage"
                         height="42" width="42">
                    <v-icon color="grey darken-2" size="22">mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Друк</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!organization.cabinet_only">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-2 success" v-on="on"
                         :loading="calc_loading"
                         v-bind="attrs" @click.stop="calcByFlat" height="42" width="42">
                    <v-icon color="white" size="22">mdi-calculator</v-icon>
                  </v-btn>
                </template>
                <span>Розрахувати нарахування</span>
              </v-tooltip>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-1" style="flex: 1; display: flex">
        <v-card-text class="pa-0">
          <v-menu
              transition="slide-y-transition"
              bottom
              offset-y
              v-if="$vuetify.breakpoint.xsOnly"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  block
                  class="white my-2"
                  v-bind="attrs"
                  v-on="on"
                  min-width="100px"
              >
                <v-icon size="18" left color="teal" class="mr-4">mdi-menu</v-icon>
                Сторінки
              </v-btn>
            </template>
            <v-list class="subtitle-1" nav dense>
              <v-list-item
                  v-if="actual_pages.accounting.show"
                  @click="tab = actual_pages.accounting.tab"
                  :class="tab === actual_pages.accounting.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Стан розрахунків</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.family.show"
                  @click="tab = actual_pages.family.tab"
                  :class="tab === actual_pages.family.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Склад сім’ї</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.privilege.show"
                  @click="tab = actual_pages.privilege.tab"
                  :class="tab === actual_pages.privilege.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Пільги</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.archive.show"
                  @click="tab = actual_pages.archive.tab"
                  :class="tab === actual_pages.archive.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Архів нарахувань</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.normative.show"
                  @click="tab = actual_pages.normative.tab"
                  :class="tab === actual_pages.normative.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Інші параметри</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.counter.show"
                  @click="tab = actual_pages.counter.tab"
                  :class="tab === actual_pages.counter.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Лічильники</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.document.show"
                  @click="tab = actual_pages.document.tab"
                  :class="tab === actual_pages.document.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Документи</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.appliance.show"
                  @click="tab = actual_pages.appliance.tab"
                  :class="tab === actual_pages.appliance.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Прилади</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.change.show"
                  @click="tab = actual_pages.change.tab"
                  :class="tab === actual_pages.change.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Зміни</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="actual_pages.flat_info.show"
                  @click="tab = actual_pages.flat_info.tab"
                  :class="tab === actual_pages.flat_info.tab ? 'active-menu-item' : ''"
              >
                <v-list-item-icon>
                  <v-icon color="success">mdi-newspaper-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Дані абонента</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tabs class="custom-tabs-2" color="success" :class="$vuetify.breakpoint.xsOnly ? 'hidden' : ''" v-model="tab">
            <v-tab
                v-if="actual_pages.accounting.show"
                class="text-left justify-start">
              Стан
            </v-tab>
            <v-tab
                v-if="actual_pages.family.show"
                class="text-left justify-start">
              Склад сім’ї
            </v-tab>
            <v-tab
                v-if="actual_pages.privilege.show"
                class="text-left justify-start">
              Пільги
            </v-tab>
            <v-tab
                v-if="actual_pages.archive.show"
                class="text-left justify-start">
              Розрахунки
            </v-tab>
            <v-tab
                v-if="actual_pages.normative.show"
                class="text-left justify-start">
              Інші параметри
            </v-tab>
            <v-tab
                v-if="actual_pages.counter.show"
                class="text-left justify-start">
              Лічильники
            </v-tab>
            <v-tab
                v-if="actual_pages.document.show"
                class="text-left justify-start">
              Документи
            </v-tab>
            <v-tab
                v-if="actual_pages.appliance.show"
                class="text-left justify-start">
              Прилади
            </v-tab>
            <v-tab
                v-if="actual_pages.change.show"
                class="text-left justify-start">
              Зміни
            </v-tab>
            <v-tab
                v-if="actual_pages.flat_info.show"
                class="text-left justify-start">
              Дані абонента
            </v-tab>

            <v-tab-item
                v-if="actual_pages.accounting.show"
                class="pa-0">
              <v-row style="flex: 0">
                <v-col cols="12" md="8">
                  <HWP_Normatives :actual="true" @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <HWP_Contacts :inner_person_hash="modal_person_hash"/>
                    </v-col>
                    <v-col cols="12" md="12">
                      <HWP_TariffGroups @fetch="calcAfterChange" :active="true" :inner_person_hash="modal_person_hash"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="flex: 1">
                <template v-if="work_place_with_pretense_and_comments">
                  <v-col cols="8">
                    <HWP_Accounts :height="'100%'" @fetch="calcAfterChange" :inner_person_hash="modal_person_hash" :short_form="work_place_with_pretense_and_comments" :volume_column_name="volume_column_name"/>
                  </v-col>
                  <v-col cols="4" :class="$vuetify.breakpoint.smAndDown ? 'pb-2' : 'pb-0'">
                    <v-row class="fill-height">
                      <v-col cols="12" md="12" style="height: 50%">
                        <FlatNotesWorkPlace :person_hash="modal_person_hash" @afterFetch="updateNoteCounter" :refresh="refresh_flat_notes"/>
                      </v-col>
                      <v-col cols="12" md="12" style="height: 50%" :class="$vuetify.breakpoint.smAndDown ? 'pb-2' : 'pb-0'">
                        <CourtCaseWorkPlace :person_hash="modal_person_hash" @afterFetch="updateCourtCaseCounter" :refresh="refresh_court_cases"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <HWP_Accounts :height="'100%'" @fetch="calcAfterChange" :inner_person_hash="modal_person_hash" :volume_column_name="volume_column_name"/>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.family.show"
                class="pa-0">
              <v-row style="display: flex; flex-direction: column">
                <v-col cols="12" md="12" style="flex: 1">
                  <HWP_Family height="100%" :inner_person_hash="modal_person_hash" :not_living="flat?.not_living || false"/>
                </v-col>
                <v-col cols="12" md="12" style="flex: 1">
                  <v-tabs color="success" class="custom-tabs-2">
                    <v-tab>Відсутні мешканці</v-tab>
                    <v-tab>Історія зміни власника</v-tab>

                    <v-tab-item>
                      <HWP_AbsentPersons @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                    </v-tab-item>
                    <v-tab-item>
                      <HWP_Owner_Changes :inner_person_hash="modal_person_hash"/>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.privilege.show"
                class="pa-0">
              <v-row style="display: flex; flex-direction: column">
                <v-col cols="12" md="12" style="flex: 1">
                  <HWP_PrivilegeOwner height="100%" :inner_person_hash="modal_person_hash"/>
                </v-col>
                <v-col cols="12" md="12" style="flex: 1">
                  <HWP_PrivilegePersons :height="'100%'"/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.archive.show"
                class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <HWP_Archive height="100%" :inner_person_hash="modal_person_hash"/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.normative.show"
                class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-card tile elevation="1" class="fill-height">
                    <v-card-text class="fill-height py-0 px-0">
                      <v-tabs color="success" class="custom-tabs-1">
                        <v-tab class="py-3">Нормативно-якісні параметри</v-tab>
                        <v-tab class="py-3">Групи тарифів</v-tab>
                        <v-tab class="py-3">Послуги</v-tab>
                        <v-tab class="py-3">Тарифи</v-tab>
                        <v-tab class="py-3">Субабоненти</v-tab>
                        <v-tab class="py-3">Реструктуризація</v-tab>

                        <v-tab-item>
                          <HWP_Normatives @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                        </v-tab-item>
                        <v-tab-item>
                          <HWP_TariffGroupsTable @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                        </v-tab-item>
                        <v-tab-item>
                          <HWP_FlatServices @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                        </v-tab-item>
                        <v-tab-item>
                          <HWP_TariffForFlat @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                        </v-tab-item>
                        <v-tab-item>
                          <HWP_FlatCounterSubAccount @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                        </v-tab-item>
                        <v-tab-item>
                          <HWP_RestructuringAgreements :inner_person_hash="modal_person_hash"/>
                        </v-tab-item>
                      </v-tabs>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.counter.show"
                class="pa-0" style="height: 100%">
              <v-row :style="$vuetify.breakpoint.mdAndUp
              ? 'display: flex; flex-direction: column; flex-wrap: nowrap; height: 78vh'
              : 'display: flex; flex-direction: column; flex-wrap: nowrap; height: 100%'">
                <v-col cols="12" md="12" class="pb-1" style="flex: 0;">
                  <FlatCounter :selected="selected" height="230px" @fetch="calcAfterChange" :inner_person_hash="modal_person_hash"/>
                </v-col>
                <v-col cols="12" md="12" class="pt-1" style="flex: 1;">
                  <v-row class="fill-height">
                    <v-col cols="12" md="9" class="fill-height">
                      <HWP_Pokaz :counter_id="selected.length ? selected[0].id : -1" height="100%"/>
                    </v-col>
                    <v-col cols="12" md="3" class="fill-height">
                      <HWP_FlatCounterOperation :counter_id="selected.length ? selected[0].id : -1" height="100%"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.document.show"
                class="pa-0">
              <v-row style="flex: 0">
                <v-col cols="12" md="12">
                  <HWP_FlatDocuments/>
                </v-col>
              </v-row>
              <v-row style="flex: 1">
                <v-col cols="12" md="12">
                  <HWP_FlatContract/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.appliance.show"
                class="pa-0">
              <v-row>
                <v-col cols="12" md="8">
                  <HWP_ApplianceByFlat @fetch="calcAfterChange"/>
                </v-col>
                <v-col cols="12" md="4">
                  <HWP_ApplianceOperation/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.change.show"
                class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <HWP_RegistrationOfChanges @fetch="calcAfterChange"/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
                v-if="actual_pages.flat_info.show"
                class="pa-0">
              <v-row>
                <v-col cols="12" md="7">
                  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f"
                          min-height="280">
                    <v-card-title class="pa-0">
                      <v-toolbar elevation="0" class="pa-0">
                        <v-toolbar-title>
                          Дані абонента {{ flat.address }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </v-card-title>
                    <v-card-text>
                      <v-form v-model="formValid" ref="form">
                        <v-row class="dense-wrapper">
                          <v-col cols="12" md="12" v-if="flat_form.not_living">
                            <v-text-field filled
                                          v-model="flat_form.address"
                                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                          :class="flat_form.address ? '' : 'req-star'"
                                          :disabled="!edit"
                                          hide-details
                                          color="grey"
                                          label="Назва приміщення"
                            />
                          </v-col>
                          <v-col cols="12" md="12">
                            <AddressElementSelect v-model="flat_form.cityId"
                                                  label="Населений пункт" filled req
                                                  select_type="city"
                                                  :disabled="!edit"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <AddressElementSelect :parent_id="flat_form.cityId" v-model="flat_form.streetId"
                                                  label="Вулиця(-ки)" filled req
                                                  select_type="street" use_parent_id
                                                  :disabled="!flat_form.cityId || !edit"
                            />
                          </v-col>
                          <v-col cols="12" md="6" style="display: flex;">
                            <AddressElementSelect :parent_id="flat_form.streetId" v-model="flat_form.buildingId"
                                                  label="Будинок(-ки)" filled req
                                                  select_type="building" use_parent_id
                                                  :disabled="!flat_form.streetId || !edit"
                                                  @selectChanged="buildingChange"
                                                  style="flex: 1"
                            />
                            <MainModal
                                :main="{ component: 'Building', title: 'Будинки' }"
                                :button="{ icon: false, color: 'secondary', buttonIcon: 'mdi-plus', buttonDepressed: true, buttonClass: 'ml-2 grey lighten-3', buttonStyle: 'height: 100%' }"
                                @updateItemModal="closeModalEmit"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="d-flex">
                            <v-text-field type="text" hide-details filled label="Особовий рахунок"
                                          v-model="flat_form.personId"
                                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                          :class="flat_form.personId ? '' : 'req-star'"
                                          :disabled="!edit"
                                          color="grey"
                                          class="mr-2"
                            />
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="grey lighten-3"
                                       elevation="1"
                                       :disabled="!edit"
                                       v-bind="attrs" v-on="on" height="100%"
                                       style="min-width: 32px; padding: 0 4px"
                                >
                                  <v-icon color="success">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list dense nav>
                                <v-list-item @click="getLastPersonId">
                                  <v-list-item-icon>
                                    <v-icon color="secondary">
                                      mdi-auto-download
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Присвоїти останій особовий
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="general_settings?.person_id_from_wsdl?.value"
                                    @click="getEpsPersonId">
                                  <v-list-item-icon>
                                    <v-icon color="teal">
                                      mdi-cloud-download-outline
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Присвоїти особовий із EPS
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field type="text" hide-details filled label="№ Квартири"
                                          v-model="flat_form.flatNumber"
                                          :rules="flat_form.building_type === 'private' ? [] : [v => !!v || 'Це поле є обов’язковим']"
                                          :class="{'req-star': flat_form.building_type !== 'private' && !flat_form.flatNumber}"
                                          color="grey"
                                          :disabled="!edit"
                                          @change="flatNumberChange"
                            />
                          </v-col>
                          <v-col cols="6" md="6">
                            <Checker :value="flat_form.checker_id" @autocompleteChange="checkerChange" :disabled="!edit"
                                     color="grey"
                            />
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field type="text" hide-details filled label="ЕІС код"
                                          v-model="flat_form.eis_code"
                                          color="grey"
                                          :disabled="!edit"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field hide-details filled label="Поштовий індекс"
                                          v-model="flat_form.postal_index"
                                          :disabled="!edit"
                                          color="grey"
                                          v-integer
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field hide-details filled label="Підїзд"
                                          v-model="flat_form.entrance"
                                          :disabled="!edit"
                                          color="grey"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field hide-details filled label="Поверх"
                                          v-model="flat_form.floor"
                                          :disabled="!edit"
                                          color="grey"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field hide-details filled label="Кімнат"
                                          v-model="flat_form.rooms"
                                          :disabled="!edit"
                                          color="grey"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-switch v-model="flat_form.not_living" hide-details
                                      :disabled="!edit"
                                      color="success"
                                      :label="flat_form.not_living ? 'Нежитлове приміщення' : 'Житлове приміщення'"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select :items="flatOwnershipTypes" hide-details filled label="Тип власності"
                                      v-model="flat_form.flatOwnershipType"
                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                      :class="flat_form.flatOwnershipType ? '' : 'req-star'"
                                      :disabled="!edit"
                                      color="grey"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-switch v-model="flat_form.disable" hide-details
                                      :disabled="!edit"
                                      color="success"
                                      :label="flat_form.disable ? 'Закритий особовий рахунок' : 'Актуальний особовий рахунок'"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <date-component label="Дата закриття" v-model="flat_form.disable_date"
                                            :disabled="!edit"
                                            :req="flat_form.disable"
                            />
                          </v-col>

                          <v-col cols="12" md="6" @click="flatSaveOrUpdate">
                            <v-btn block :color="edit ? 'success' : 'secondary'" height="50">
                              <v-icon left dark size="24" class="mr-3">
                                {{ edit ? 'mdi-content-save-outline' : 'mdi-circle-edit-outline' }}
                              </v-icon>
                              {{ edit ? 'Зберегти' : 'Редагувати' }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="5">
                  <v-dialog persistent v-model="show_send_code" max-width="500">
                    <v-card>
                      <v-card-title>
                        Надсилання коду для реєстрації в особистому кабінеті
                      </v-card-title>
                      <v-card-text>
                        <v-form ref="sendForm" v-model="form_send_valid">
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                  v-model="send_code_email"
                                  type="email"
                                  label="Email"
                                  :rules="emailRules"
                                  required
                                  filled
                                  color="grey"
                              />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed text color="secondary darken-1"
                               @click.stop="sendInvitationCode"
                               :loading="send_code_loading"
                               class="button-accept">
                          <v-icon left>mdi-content-save</v-icon>
                          Надіслати
                        </v-btn>
                        <v-btn depressed text tile color="grey" @click="closeModal">
                          <v-icon left>mdi-close</v-icon>
                          Відмінити
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-card tile elevation="1" class="fill-height" min-height="280">
                    <v-card-text class="text-center">
                      <div class="mb-3" style="font-size: 1rem">Код для особистого кабінету</div>
                      <h2 style="font-size: 3rem">
                        {{ flat.person_hash }}
                        <v-btn icon class="grey lighten-3 mb-2" height="42" width="42" @click.stop="openModal">
                          <v-icon color="success">
                            mdi-email-fast-outline
                          </v-icon>
                        </v-btn>
                      </h2>
                    </v-card-text>
                    <v-card-text>
                      <v-alert type="success" text>
                        Данний код використовується для реєстрації нових користувачів в особистому кабінеті, саме для
                        цього абонента.
                        Також цей код друкується при формуванні рахунків на оплату.
                      </v-alert>
                    </v-card-text>

                    <v-card-text>
                      <v-btn block depressed large
                             :to="{name: 'RepresentPersonalWorkplace'}"
                      >Спробувати особистий кабінет
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {flat_ownership_types_select} from "@/utils/icons";
import {mapActions, mapGetters} from "vuex";
import {GET_FLAT, NEXT_ITEM, UPDATE_FLAT} from "@/store/actions/flat";
import {FETCH_FLAT_INDICATOR_TYPES, FETCH_FLAT_INDICATORS} from "@/store/actions/flat_indicators";
import {CALC_BY_FLAT} from "@/store/actions/calculation";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";
import {ALERT_SHOW} from "@/store/actions/alert";
import flatAPI from "@/utils/axios/flat";
import flatNoteAPI from "@/utils/axios/flat_notes"
import flatCourtCaseAPI from "@/utils/axios/flat_court_case"
import spreedSheetAPI from "@/utils/axios/spreed_sheet"

import {onBeforeUnload} from "@/utils/icons"
import update from "@/mixins/update";
import soapAPI from "@/utils/axios/soap"

export default {
  name: "HousePayWorkPlace",
  props: {
    modal_item: {
      type: Boolean,
      default: false
    },
    modal_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  mixins: [update],
  components: {
    HWP_ApplianceByFlat: () => import("@/components/HouseWorkPlace/HWP_ApplianceByFlat.vue"),
    HWP_ApplianceOperation: () => import("@/components/HouseWorkPlace/HWP_ApplianceOperation.vue"),
    HWP_RegistrationOfChanges: () => import("@/components/HouseWorkPlace/HWP_RegistrationOfChanges"),
    HWP_FlatCounterOperation: () => import("@/components/HouseWorkPlace/HWP_FlatCounterOperation"),
    HWP_PrivilegeOwner: () => import("@/components/HouseWorkPlace/HWP_PrivilegeOwner"),
    HWP_PrivilegePersons: () => import("@/components/HouseWorkPlace/HWP_PrivilegePersons"),
    PrintReport: () => import("@/components/PrintReport"),
    HWP_Normatives: () => import("@/components/HouseWorkPlace/HWP_Normatives"),
    HWP_Contacts: () => import("@/components/HouseWorkPlace/HWP_Contacts"),
    HWP_TariffGroups: () => import("@/components/HouseWorkPlace/HWP_TariffGroups"),
    HWP_Accounts: () => import("@/components/HouseWorkPlace/HWP_Accounts"),
    HWP_Family: () => import("@/components/HouseWorkPlace/HWP_Family"),
    HWP_Owner_Changes: () => import("@/components/HouseWorkPlace/HWP_Owner_Changes"),
    HWP_Archive: () => import("@/components/HouseWorkPlace/HWP_Archive"),
    HWP_TariffGroupsTable: () => import("@/components/HouseWorkPlace/HWP_TariffGroupsTable"),
    FlatCounter: () => import("@/components/FlatCounter"),
    HWP_Pokaz: () => import("@/components/HouseWorkPlace/HWP_Pokaz"),
    PrintPage: () => import("@/components/PrintPage"),
    HWP_FlatDocuments: () => import("@/components/HouseWorkPlace/HWP_FlatDocuments"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker"),
    HWP_FlatContract: () => import("@/components/HouseWorkPlace/HWP_FlatContract"),
    HWP_AbsentPersons: () => import("@/components/HouseWorkPlace/HWP_AbsentPersons"),
    HWP_FlatServices: () => import("@/components/HouseWorkPlace/HWP_FlatServices.vue"),
    HWP_RestructuringAgreements: () => import("@/components/HouseWorkPlace/HWP_RestructuringAgreements.vue"),
    HWP_TariffForFlat: () => import("@/components/HouseWorkPlace/HWP_TariffForFlat.vue"),
    HWP_FlatCounterSubAccount: () => import("@/components/HouseWorkPlace/HWP_FlatCounterSubAccount.vue"),
    UserEventLogger: () => import("@/components/UserEventLogger"),
    FlatNotes: () => import("@/components/FlatNotes.vue"),
    FlatNotesWorkPlace: () => import("@/components/FlatNotesWorkPlace.vue"),
    FlatCourtCases: () => import("@/components/FlatCourtCase.vue"),
    CourtCaseWorkPlace: () => import("@/components/CourtCaseWorkPlace.vue"),
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      flat: 'getFlat',
      indicators: 'getFLatIndicators',
      current_month: 'getCurrentMonth',
      organization: 'getCurrentOrganization',
      userId: 'getUserId',
      general_settings: 'getGeneralSettings'
    }),
    pages() {
      return {
        accounting: {show: this.general_settings?.work_place_accounting?.value, tab: 0},
        family: {show: this.general_settings?.work_place_family?.value, tab: 1},
        privilege: {show: this.general_settings?.work_place_privilege?.value, tab: 2},
        archive: {show: this.general_settings?.work_place_archive?.value, tab: 3},
        normative: {show: this.general_settings?.work_place_normative?.value, tab: 4},
        counter: {show: this.general_settings?.work_place_counter?.value, tab: 5},
        document: {show: this.general_settings?.work_place_document?.value, tab: 6},
        appliance: {show: this.general_settings?.work_place_appliance?.value, tab: 7},
        change: {show: this.general_settings?.work_place_change?.value, tab: 8},
        flat_info: {show: this.general_settings?.work_place_flat_info?.value, tab: 9}
      }
    },
    actual_pages() {
      const new_item = {}
      Object.entries(this.pages).filter(i => i[1].show).forEach((item, idx) => {
        new_item[item[0]] = {show: true, tab: idx}
      })
      Object.entries(this.pages).filter(i => !i[1].show).forEach(item => {
        new_item[item[0]] = {show: false, tab: -1}
      })
      return new_item
    },
    work_place_with_pretense_and_comments() {
      return this.general_settings?.work_place_with_pretense_and_comments?.value || false
    },
    volume_column_name() {
      return this.general_settings?.work_place_volume_column_name?.value || 'Об’єм'
    }
  },
  data() {
    return {
      tab: 0,
      search: null,
      headers: [
        {text: 'Особовий рах.', value: 'person_id', width: 150},
        {text: 'Адреса', value: 'address'},
        {text: 'Власник', value: 'flat_owner_short_name'},
        {text: 'Площа', value: 'square', width: 120, align: 'right', sortable: false}
      ],
      search_flats: [],
      slider: null,
      selected: [],
      selected_appliance: [],
      edit: false,
      prev_button_visible: true,
      next_button_visible: true,
      printPageModal: false,
      reportPageModal: false,
      formValid: false,
      print_report_component_name: null,
      print_report_additional_id: null,
      calc_loading: false,
      show_event_logger: false,
      show_search_window: false,
      flat_form: {
        cityId: null,
        streetId: null,
        buildingId: null,
        personId: '',
        flatNumber: '',
        flatOwnershipType: null,
        not_living: null,
        entrance: 0,
        floor: 0,
        rooms: 0,
        building_type: '',
        disable: false,
        disable_date: null,
        checker_id: null,
        address: '',
        postal_index: null,
        eis_code: null
      },
      flatOwnershipTypes: flat_ownership_types_select,
      person_id_soap: null,
      person_id_soap_get: false,
      show_send_code: false,
      send_code_email: '',
      form_send_valid: false,
      send_code_loading: false,
      emailRules: [
        v => !!v || 'Це поле є обов’язковим',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Введіть коректний Email'
        }
      ],
      reload_buildings: false,
      show_flat_notes: false,
      flat_notes: {
        count: 0,
        warning: 0
      },
      show_court_cases: false,
      court_cases: {
        count: 0
      },
      refresh_flat_notes: false,
      refresh_court_cases: false,
      additional_print_form: []
    }
  },
  methods: {
    ...mapActions({
      fetchOne: GET_FLAT,
      fetchIndicators: FETCH_FLAT_INDICATORS,
      fetchIndicatorTypes: FETCH_FLAT_INDICATOR_TYPES,
      calculateByFlat: CALC_BY_FLAT,
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      next_item: NEXT_ITEM,
      getAccountArchive: GET_ARCHIVE
    }),
    fetchFlatsBySearch(text) {
      flatAPI.search_by_text(text)
          .then(response => response.data)
          .then(data => {
            this.search_flats = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    searchFlatByText(e) {
      if (e && e.length >= 2) {
        this.fetchFlatsBySearch(e)
      } else {
        this.search_flats = []
      }
    },
    openWorkPlace(payload) {
      if (!payload.person_hash) return
      if (this.tab) {
        this.$router.push({
          name: `WorkPlace`,
          params: {"person_hash": payload.person_hash},
          query: {tab: this.tab.toString()}
        })
      } else {
        this.$router.push({name: `WorkPlace`, params: {"person_hash": payload.person_hash}})
      }
    },
    afterReloadBuildings() {
      this.reload_buildings = false
    },
    closeModalEmit(payload) {
      if (payload.payload) {
        if (payload.payload.emit) {
          this.reload_buildings = true
        }
      }
    },
    openModal() {
      this.send_code_email = this.flat.email || ''
      this.show_send_code = true
    },
    sendInvitationCode() {
      this.$refs.sendForm.validate()
      if (!this.form_send_valid) {
        this.form_send_valid = false
        return
      }
      const payload = {
        person_hash: this.flat.person_hash,
        email: this.send_code_email,
        url: window.location.origin
      }

      this.send_code_loading = true

      flatAPI.sendPersonalCabinetCode(payload)
          .then(response => response.data)
          .then(data => {
            this.$store.commit(ALERT_SHOW,
                {
                  text: data.text,
                  color: 'success'
                })
            this.closeModal()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.send_code_loading = false
          })
    },
    closeModal() {
      this.show_send_code = false
      this.send_code_email = ''
    },
    openUserEventLogger() {
      this.show_event_logger = true
    },
    openFlatNotes() {
      this.show_flat_notes = true
      this.refresh_flat_notes = false
    },
    openCourtCaseNotes() {
      this.show_court_cases = true
      this.refresh_court_cases = false
    },
    openSearchWindow() {
      this.show_search_window = true
    },
    calcAfterChange() {
      if (this.$route.name === 'WorkPlace' || this.$route.name === 'DebtWorkCalls') {
        this.calcByFlat()
      }
    },
    getFlatFormError() {
      let text = ''
      const errors = []

      const fields = [
        {field: 'cityId', text: 'Населений пункт'},
        {field: 'streetId', text: 'Вулиця'},
        {field: 'buildingId', text: 'Будинок'},
        {field: 'personId', text: 'Особовий рахунок'},
        {field: 'flatNumber', text: 'Номер квартири'},
        {field: 'flatOwnershipType', text: 'Тип власності'},
      ]

      if (this.flat_form.not_living) {
        fields.push(
            {field: 'address', text: 'Адреса'}
        )
      }

      if (this.flat_form.disable) {
        fields.push(
            {field: 'disable_date', text: 'Дата закриття'}
        )
      }

      fields.forEach(item => {
        if (this.flat_form[item.field] === null || this.flat_form[item.field] === undefined) {
          errors.push(item.text)
        }
      })

      if (errors.length) {
        text = `Не заповнено: ${errors.join(', ')}`
        return text
      }
      return ''
    },
    flatSaveOrUpdate() {
      if (this.edit) {
        if (!this.formValid) {
          this.formValid = false
          this.$store.commit(ALERT_SHOW, {
            text: this.getFlatFormError(),
            color: 'error lighten-1'
          })
          return
        }

        const payload = {
          person_id: this.flat_form.personId,
          building_id: this.flat_form.buildingId,
          street_id: this.flat_form.streetId,
          flat_number: this.flat_form.flatNumber,
          flat_type: this.flat_form.flatOwnershipType,
          not_living: this.flat_form.not_living,
          person_hash: this.flat.person_hash,
          entrance: this.flat_form.entrance,
          floor: this.flat_form.floor,
          rooms: this.flat_form.rooms,
          checker_id: this.flat_form.checker_id,
          disable: this.flat_form.disable,
          disable_date: this.flat_form.disable_date,
          address: this.flat_form.address,
          postal_index: this.flat_form.postal_index,
          eis_code: this.flat_form.eis_code
        }

        const person_id = payload.person_id
        const person_id_soap = this.person_id_soap
        const person_id_soap_get = this.person_id_soap_get
        const owner = this.flat.flat_owner === undefined || this.flat.flat_owner === null ? 'Власник відсутній' : this.flat.flat_owner.full_name
        const address = this.flat.full_address || `${payload.building_id}_${payload.flat_number}_${owner}`
        const eps_id = this.organization.eps_id

        flatAPI.check_person_id_id(
            {
              person_id: this.flat_form.personId,
              person_hash: this.flat.person_hash,
            })
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.$store.dispatch(UPDATE_FLAT, payload).then(res => {
                  if (res) {
                    this.edit = false
                    this.calcByFlat()
                    if (person_id === person_id_soap && person_id_soap_get && eps_id) {
                      soapAPI.create_person_id(
                          {
                            person_id,
                            address
                          }
                      )
                          .catch(err => {
                            const error = err.response.data.detail;
                            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                          })
                    }
                  }
                })
              } else {
                this.$store.dispatch(ALERT_SHOW, {
                  text: 'Абонент із таким особовим рахунком вже існує',
                  color: 'error lighten-1'
                })
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })

      } else {
        this.edit = true
      }
    },
    buildingChange(payload) {
      this.flat_form.building_type = (payload || {}).element_type || null
    },
    flatNumberChange() {
      this.flat_form.flatNumber = this.flat_form.flatNumber.trim()
    },
    checkerChange(payload) {
      this.flat_form.checker_id = payload?.value || null
    },
    calcByFlat() {
      this.calc_loading = true
      const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.modal_person_hash
      this.calculateByFlat(person_hash)
          .then(e => {
                if (e) {
                  this.fetchFlatServices(person_hash)
                  this.getAccountArchive(person_hash)
                }
              }
          )
          .catch()
          .finally(() => {
            this.calc_loading = false
          })
    },
    nextItem(person_hash, right) {
      const payload = {
        person_hash: person_hash,
        right: right,
        city_id: this.flat.city_id
      }
      this.next_item(payload)
          .then(e => {
            if (e) {
              if (this.$route.params.person_hash !== e) {
                if (right) {
                  this.next_button_visible = true
                } else {
                  this.prev_button_visible = true
                }
                if (this.tab) {
                  this.$router.push({
                    name: `WorkPlace`,
                    params: {"person_hash": e.toString()},
                    query: {tab: this.tab.toString()}
                  })
                } else {
                  this.$router.push({name: `WorkPlace`, params: {"person_hash": e.toString()}})
                }
              } else {
                if (right) {
                  this.prev_button_visible = true
                  this.next_button_visible = false
                } else {
                  this.prev_button_visible = false
                  this.next_button_visible = true
                }
              }
            }
          })
    },
    openPrintPage() {
      this.printPageModal = true
    },
    openReportPage(report_name, add_id=null) {
      this.print_report_component_name = report_name
      this.print_report_additional_id = add_id
      this.reportPageModal = true
    },
    getEpsPersonId() {
      if (this.general_settings?.person_id_from_wsdl?.value && this.organization.eps_id) {
        soapAPI.get_person_id()
            .then(response => response.data)
            .then(data => {
              this.flat_form.personId = `${data}`
              if (data) {
                this.person_id_soap = this.flat_form.personId
                this.person_id_soap_get = true
                this.$store.dispatch(ALERT_SHOW, {text: 'Особовий отримано успішно', color: 'success'})
              } else {
                this.person_id_soap = null
                this.person_id_soap_get = false
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.person_id_soap = null
              this.person_id_soap_get = false
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    getLastPersonId() {
      const payload = {
        person_hash: '',
        person_id: this.flat_form.personId ? this.flat_form.personId : '',
      }
      flatAPI.get_max_person_id(payload)
          .then(response => response.data)
          .then(data => {
            this.flat_form.personId = data
            this.$store.dispatch(ALERT_SHOW, {text: 'Особовий присвоїно успішно', color: 'success'})
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    generate_pages_show () {
      this.pages.accounting.show = this.general_settings?.work_place_accounting?.value || false
      this.pages.family.show = this.general_settings?.work_place_family?.value || false
      this.pages.privilege.show = this.general_settings?.work_place_privilege?.value || false
      this.pages.archive.show = this.general_settings?.work_place_archive?.value || false
      this.pages.normative.show = this.general_settings?.work_place_normative?.value || false
      this.pages.counter.show = this.general_settings?.work_place_counter?.value || false
      this.pages.document.show = this.general_settings?.work_place_document?.value || false
      this.pages.appliance.show = this.general_settings?.work_place_appliance?.value || false
      this.pages.change.show = this.general_settings?.work_place_change?.value || false
      this.pages.flat_info.show = this.general_settings?.work_place_flat_info?.value || false
    },
    closeModalItem() {
      this.$emit('closeWorkPlaceModalItem')
    },
    updateNoteCounter(payload) {
      this.flat_notes.count = (payload || {count: 0, warning: 0}).count
      this.flat_notes.warning = (payload || {count: 0, warning: 0}).warning
    },
    updateCourtCaseCounter(payload) {
      this.court_cases.count = (payload || {count: 0, warning: 0}).count
    },
    afterComponentNotesUpdate(payload) {
      this.refresh_flat_notes = payload
    },
    afterComponentCourtCasesUpdate(payload) {
      this.refresh_court_cases = payload
    }
  },
  async created() {
    await this.$store.dispatch('CLEAR_WORKPLACE')
    if (this.modal_item) {
      await this.fetchOne(this.modal_person_hash)
    } else {
      await this.fetchOne(this.$route.params.person_hash)
    }

    await this.generate_pages_show()

    if (this.$route.query.tab) {
      this.tab = +this.$route.query.tab
    }
    this.flat_form.cityId = this.flat.city_id
    this.flat_form.streetId = this.flat.street_id
    this.flat_form.buildingId = this.flat.building_id
    this.flat_form.personId = this.flat.person_id
    this.flat_form.flatNumber = this.flat.flat_number
    this.flat_form.flatOwnershipType = this.flat.flat_type
    this.flat_form.not_living = this.flat.not_living
    this.flat_form.building_type = this.flat.building_type
    this.flat_form.checker_id = this.flat.checker_id
    this.flat_form.disable = this.flat.disable
    this.flat_form.disable_date = this.flat.disable_date
    this.flat_form.entrance = this.flat.entrance
    this.flat_form.floor = this.flat.floor
    this.flat_form.address = this.flat.address
    this.flat_form.postal_index = this.flat.postal_index
    this.flat_form.eis_code = this.flat.eis_code

    if (this.flat_form.disable) {
      this.$store.commit(ALERT_SHOW, {text: 'ЦЕЙ ОСОБОВИЙ РАХУНОК ЗАКРИТО', color: 'red darken-1'})
    }
    if (!this.work_place_with_pretense_and_comments) {
      let flat_notes_count, court_case_count

      if (this.modal_item) {
        flat_notes_count = await flatNoteAPI.get_count(this.modal_person_hash)
        court_case_count = await flatCourtCaseAPI.get_count(this.modal_person_hash)

      } else {
        flat_notes_count = await flatNoteAPI.get_count(this.$route.params.person_hash)
        court_case_count = await flatCourtCaseAPI.get_count(this.$route.params.person_hash)
      }

      this.flat_notes.count = flat_notes_count.data.count
      this.flat_notes.warning = flat_notes_count.data.warning

      this.court_cases.count = court_case_count.data.count
    }
    const additional_print_form = await spreedSheetAPI.select(true)
    this.additional_print_form = additional_print_form.data
  },
  mounted() {
    window.removeEventListener('beforeunload', onBeforeUnload)
  },
  beforeDestroy() {
    window.addEventListener('beforeunload', onBeforeUnload)
  }
}
</script>

<style scoped lang="scss">
.active-menu-item {
  background: lightgrey !important;
}

.custom-tabs-1 {
  display: flex;
  flex-direction: column;
  height: 100%;

  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
    flex: 1;
  }

  :deep(> div[role="tablist"]) {
    background-color: transparent !important;
    height: 40px !important;
  }
}

.custom-tabs-2 {
  display: flex;
  flex-direction: column;
  height: 100%;

  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
    flex: 1;
  }

  :deep(> div[role="tablist"]) {
    background-color: #f5f5f5 !important;
    height: 30px !important;
  }

  :deep(.v-window.v-item-group) {
    height: 100%;
  }

  :deep(.v-window-item--active) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  :deep(.v-window-item) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.custom-tabs.hidden {
  :deep(.v-item-group) {
    height: auto !important;

    .v-slide-group__wrapper {
      display: none;
    }

    .v-slide-group__prev {
      display: none !important;
    }

    .v-slide-group__next {
      display: none !important;
    }
  }
}

.media-print {
  background-color: #ff1111 !important;
}
</style>
